<!-- =========================================================================================
    File Name: ResetPassword.vue
    Description: Reset Password Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="h-screen flex w-full bg-img fontMedium">
    <div
      class="vx-col sm:w-3/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center mycard"
    >
      <vx-card>
         <div
            class=" absolute flex inset-x-0 top-0 mt-6 justify-center "

            slot="no-body"
          >
            <img
              src="@/assets/images/logo.png"
              style="max-width:150px ;"
            />
          </div>
        <div
          slot="no-body"
          class="full-page-bg-color"
          style="background: transparent radial-gradient(closest-side at 50% 50%, #2f5f80 0%, #003267 100%) 0% 0% no-repeat padding-box;
                box-shadow: -3px 0px 22px #00000029;
                         border-radius:31px 31px 31px 31px;
             opacity: 1;"
        >
          <div class="vx-row">
            <div
              class="vx-col  hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center"
              style="background-color:#004477"
            >
              <p
                style="text-align: center;
                 font: normal normal 100 30px/51px Futura PT;
                  letter-spacing: 0px;
                     color: #FFFFFF;
                     opacity: 1;"
              >
              {{$t("proceed with your onilne appointment booking")}}
              </p>

            </div>
            <div
              class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center  d-theme-dark-bg"
              style="border-radius: 31px 31px 31px 31px;"
            >
              <div class="p-8" style="margin-top: 70px;">
                <div class="vx-card__title mb-8">
                  <!-- <h4 class="mb-4">Reset password</h4>
                  <p>Please enter your new password.</p> -->
                </div>
                 <div
                    class="vx-col  sm:w-full md:w-full  mx-auto self-center  d-theme-dark-bg">
                   <!-- <vs-input
                    :label-placeholder="$t('code')"
                    v-model="code"
                    class="w-full mb-6"
                    /> -->
            <div class="vx-row text-center">
              <label  class="col lg:w-1/4 w-full mt-3" for="fname">date:</label>
                            <vs-input name="fname" class="col lg:w-2/3  w-full mb-6" placeholder="" v-model="sessionModel.SessionDate"/>
            </div>
            <div class="vx-row text-center">
              <label  class="col lg:w-1/4 w-full mt-3" for="fname">Time:</label>
                            <vs-input name="fname" class="col lg:w-2/3  w-full mb-6" placeholder="" v-model="sessionModel.TimeFrom"/>
            </div>
            <div class="vx-row text-center ">
              <label  class="col lg:w-1/4 w-full mt-3" for="fname">Doctor:</label>
                            <vs-input name="fname" class="col lg:w-2/3  w-full mb-6" placeholder="" v-model="sessionModel.DoctorName"/>
            </div>
            <div class="vx-row text-center">
              <label  class="col lg:w-1/4 w-full mt-3" for="fname">Duration:</label>
                            <vs-input name="fname" class="col lg:w-2/3 w-full mb-6" placeholder="" v-model="sessionModel.DurationInMinutes"/>
            </div>
                </div>

                <div
                  class="flex flex-wrap justify-center flex-col-reverse sm:flex-row"
                >

                  <vs-button
                    class="w-full sm:w-auto text-center"
                     color="rgb(62, 201, 214)"
                    @click="Preceed"
                    >{{$t("Preceed")}}</vs-button
                  >
                   </div>
                  <div class="text-center">
                    <vs-button
                    type="line"
                    color="black"

                    to="/pages/login"
                    class="w-full  sm:w-auto mb-8 sm:mb-auto mt-3 sm:mt-auto"
                    >{{$t("Back")}}</vs-button
                  >
                  </div>
                  <!-- <vs-button type="line" line-origin="left" color="success">Success</vs-button> -->

              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import moduleUser from "@/store/user/moduleUser.js";

export default {
  data() {
    return {
      passwordType: "password",
      contains_eight_characters: false,
      contains_number: false,
      contains_uppercase: false,
      contains_special_character: false,
      email: this.$route.params.email,
      password: "",
      code: null,
      password2: null
    };
  },
   props: {
   
    sessionModel: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
      Preceed(){
            debugger
              var model = {};
              model.PatientID =this.$store.state.AppActiveUser.Patient.ID;
              model.DoctorSessionID = this.sessionModel.SessionID;
              model.DoctorID = this.sessionModel.DoctorID;
              model.PaymentStatusID = 1; //pending
              model.StatusID = 1; 
              this.$vs.loading();

            this.$store.dispatch("DoctorList/AddPatientReservationSession", model).then(res=>{
              if(res.Status == 200){
                          this.$vs.loading.close();
                //
              }

            }).catch(()=>{
              this.$vs.loading.close();

            });
      },
  },
  created() {
    if (!moduleUser.isRegistered) {
      this.$store.registerModule("UserList", moduleUser);
      moduleUser.isRegistered = true;
    }
     
  },
  mounted() {
    this.isMounted = true;
  }
};
</script>

<style>
.fontMedium{
font-family: "futuraMedim";
/* font: normal normal 300 24px/31px "futuraMedim"; */
}
.is_valid {
  color: rgba(136, 152, 170, 0.8);
}
.is_valid:before {
  width: 50%;
}
li {
  margin-bottom: 8px;
  color: #525f7f;
  position: relative;
}

li:before {
  content: "";
  width: 0%;
  height: 2px;
  background: #2ecc71;
  position: absolute;
  left: 0;
  top: 50%;
  display: block;
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.mycard .vx-card {
  background: unset;
}

</style>
